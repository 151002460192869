export default {
    head: {
        aboutus: "<p>燕云驿采电子采购交易平台</p>",
        address: "",
        citycode: "130000",
        guid: "AEE20DFD3BC94C2D8CB7FCF173D88137",
        icp: "冀ICP备14012003号-1",
        logofileguid: "",
        logourl:"/images/logo.png",
        meta: "燕云驿采电子采购交易平台",
        planname: "yyyc",
        tel: "0311-89256052",
        helptel:'400-780-9998',
        title: "燕云驿采电子采购交易平台",
        tbrLoginUrl:'http://27.128.169.238:9001/newtbr/index.html#/login?plan=yyyc',
    },
  
}
