<template>
    <header class="header" :class="{fixed: fixed}">
        <div class="heads">
            <div class="heads_con">
                <div style="float: left;">您好，{{head.title}}欢迎您！</div> 
                <div style="float: right;">
                    客服电话：{{head.tel}}
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    技术支持：{{head.helptel}}
                </div>
            </div>
        </div>
        <div class="content">
            <img 
                class="logo header-com" 
                v-if="head.logourl"
                :src="head.logourl"
            />
            <!-- <div class="head_logo">
                <a href="/">
                    {{head.title}}
                </a>
            </div> -->

            <div class="nav-list header-com">
                <template v-for="(item, index) in $store.state.config.nav">
                    <a 
                    class="nav-item" 
                    :key="index"
                    :href="item.href+'?featurecode='+head.planname"
                    :class="{active: isActive(item)}"
                    @click="clickHandler($event, item.click)"
                    >
                        {{item.title}}
                    </a>
                </template>
            </div>
            <!-- <div class="search">
                <el-input v-model="searchText" class="input-search" size="middle">
                    <el-button 
                        placeholder="请输入搜索信息" 
                        slot="append" 
                        @click="searchHandler">
                            搜索
                    </el-button>
                </el-input>
            </div> -->
            <div class="loginReg">
                <a 
                    class="item"
                    @click="login"
                >
                    登录
                </a>
                <a 
                    class="item primary"
                    href="/register"
                    target="_blank"
                >
                    注册
                </a>
            </div>
        </div>

        <slot></slot>
    </header>
</template>

<script>
import CONFIG from '@config/config';
import { request } from 'http';
import appNode from '@js/app-node';

export default {
    components: {
        
    },
    props: {
        theme: {
            type: String,
            default: ''
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            useSearchDeal: false,   //是否使用 (查交易中心、查公告、查企业) 搜索组件
            searchText:'',
            head:CONFIG.head,
        }
    },
    computed: {
        
    },
    methods: {
        isActive(item) {
            var path = this.$route.path,
                href = item.href,
                activeReg = item.activeReg;

            if (href === path) {
                return true;
            }

            if (path === href) {
                return true;
            }

            if (activeReg && activeReg.test(path)) {
                return true;
            }

            return false;
        },
        clickHandler(e, cb) {
            if (cb) {
                e.preventDefault();
                
                var fun = new Function(cb);
                fun.call(this);
            }
        },
        // 搜索
        searchHandler() {
            this.goto({
                path: '/business',
                query: {
                    searchcontent: this.searchText,
                },
            })
        },
        // 登录
        login() {
            window.open('/biddingWeb/pages/chooseusersub.html?indexUrl=' + window.btoa(window.location.href)+'&title=' + escape(this.head.title)+'&plan='+CONFIG.head.planname)
        },
    },
    mounted: function() {
   
    },
   created() {
    

    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import '@css/var.scss';
    
	.header{
		overflow: hidden;
		cursor: default;
		height: 120px;
        background: white;
        box-shadow: 0px 1px 6px 0px rgba(61, 126, 255, 0.15);

        &.fixed{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10000;
            min-width: 1200px;
        }

		.header-com{ 
			float:left;
		}

		img.logo{
            width:387px;
			
			margin: 20px 0;
		}

		.nav-list{
			font-size: 16px;
			overflow: hidden;
            padding: 32px 0;
            margin-left: 100px;

			.nav-item{
				float: left;
				cursor: pointer;
				color: #333;
                position: relative;
                line-height: 1em;
                font-size: inherit;

				&:hover{
					color: $primaryColor;
				}

				& + .nav-item{
					margin-left: 44px;
				}

				&.active{
					font-weight: bolder;
                    color: $primaryColor;
                    position: relative;

				}
			}
		}
        .heads{
            width: 100%;
            height:40px;
            line-height: 40px;
            background: #fcfcfc;
            border-bottom: 1px solid #f0f0f0;
        }
        .heads_con{
            width: 1200px;
            margin:0 auto;
        }
        .head_a{
            width: 100%;
            height:90px;
            background: #fff;
        }
        .head_logo{
            margin-top: 20px;
            min-width: 200px;
            float:left;
            font-size: 26px;
            font-weight: 700;
		}
        .head_logo a{
            color: #64ad51;
        }
        .search{ 
            float:right;
            margin-top: 15px;
            ::v-deep .el-input__inner{ height: 30px; }
            ::v-deep .el-button{ 
                background: #64ad51; 
                color: #fff; 
                padding: 8px 20px; 
                border-top-left-radius: 0; 
                border-bottom-left-radius: 0;
            }
        }

        .loginReg{
            float:right;
            margin-top: 25px;
            .item{
                float: left;
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                margin-left: 20px;
            }
            .item.primary{
                background: #64ad51;
                color: #fff;
            }
            .item:hover{
                background: #64ad51;
                color: #fff;
            }
        }
	}

    .child-item{
        color: #333333;
    }
</style>
