import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ff38534 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _0824285a = () => interopDefault(import('..\\pages\\business\\index.vue' /* webpackChunkName: "pages/business/index" */))
const _e6a7d3ea = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _e14e7b36 = () => interopDefault(import('..\\pages\\guide\\index.vue' /* webpackChunkName: "pages/guide/index" */))
const _88977940 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _a0293de0 = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _1a229e20 = () => interopDefault(import('..\\pages\\business\\detail.vue' /* webpackChunkName: "pages/business/detail" */))
const _2773a24e = () => interopDefault(import('..\\pages\\business\\detail-sd.vue' /* webpackChunkName: "pages/business/detail-sd" */))
const _53273833 = () => interopDefault(import('..\\pages\\news\\detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _d6974f38 = () => interopDefault(import('..\\pages\\business\\components\\file-download.vue' /* webpackChunkName: "pages/business/components/file-download" */))
const _5fe4185e = () => interopDefault(import('..\\pages\\business\\components\\link-paper.vue' /* webpackChunkName: "pages/business/components/link-paper" */))
const _6156a653 = () => interopDefault(import('..\\pages\\business\\components\\search-bar.vue' /* webpackChunkName: "pages/business/components/search-bar" */))
const _7330d90c = () => interopDefault(import('..\\pages\\business\\components\\table-hxrgs.vue' /* webpackChunkName: "pages/business/components/table-hxrgs" */))
const _00de50e3 = () => interopDefault(import('..\\pages\\register\\components\\btn-yzm.vue' /* webpackChunkName: "pages/register/components/btn-yzm" */))
const _7dbafd3c = () => interopDefault(import('..\\pages\\register\\components\\captcha.vue' /* webpackChunkName: "pages/register/components/captcha" */))
const _5035c851 = () => interopDefault(import('..\\pages\\register\\components\\input-yzm.vue' /* webpackChunkName: "pages/register/components/input-yzm" */))
const _ef816d80 = () => interopDefault(import('..\\pages\\register\\components\\tos.vue' /* webpackChunkName: "pages/register/components/tos" */))
const _2b6ba35a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _53ce5eed = () => interopDefault(import('..\\pages\\index\\sections\\banner.vue' /* webpackChunkName: "pages/index/sections/banner" */))
const _ef51e17e = () => interopDefault(import('..\\pages\\index\\sections\\business.vue' /* webpackChunkName: "pages/index/sections/business" */))
const _619064dc = () => interopDefault(import('..\\pages\\index\\sections\\enters.vue' /* webpackChunkName: "pages/index/sections/enters" */))
const _e2be1558 = () => interopDefault(import('..\\pages\\index\\sections\\news.vue' /* webpackChunkName: "pages/index/sections/news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2ff38534,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/business",
    component: _0824285a,
    alias: "/business/index.html",
    name: "business"
  }, {
    path: "/download",
    component: _e6a7d3ea,
    alias: "/download/index.html",
    name: "download"
  }, {
    path: "/guide",
    component: _e14e7b36,
    alias: "/guide/index.html",
    name: "guide"
  }, {
    path: "/news",
    component: _88977940,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/register",
    component: _a0293de0,
    alias: "/register/index.html",
    name: "register"
  }, {
    path: "/business/detail",
    component: _1a229e20,
    alias: "/business/detail/index.html",
    name: "business-detail"
  }, {
    path: "/business/detail-sd",
    component: _2773a24e,
    alias: "/business/detail-sd/index.html",
    name: "business-detail-sd"
  }, {
    path: "/news/detail",
    component: _53273833,
    alias: "/news/detail/index.html",
    name: "news-detail"
  }, {
    path: "/business/components/file-download",
    component: _d6974f38,
    alias: "/business/components/file-download/index.html",
    name: "business-components-file-download"
  }, {
    path: "/business/components/link-paper",
    component: _5fe4185e,
    alias: "/business/components/link-paper/index.html",
    name: "business-components-link-paper"
  }, {
    path: "/business/components/search-bar",
    component: _6156a653,
    alias: "/business/components/search-bar/index.html",
    name: "business-components-search-bar"
  }, {
    path: "/business/components/table-hxrgs",
    component: _7330d90c,
    alias: "/business/components/table-hxrgs/index.html",
    name: "business-components-table-hxrgs"
  }, {
    path: "/register/components/btn-yzm",
    component: _00de50e3,
    alias: "/register/components/btn-yzm/index.html",
    name: "register-components-btn-yzm"
  }, {
    path: "/register/components/captcha",
    component: _7dbafd3c,
    alias: "/register/components/captcha/index.html",
    name: "register-components-captcha"
  }, {
    path: "/register/components/input-yzm",
    component: _5035c851,
    alias: "/register/components/input-yzm/index.html",
    name: "register-components-input-yzm"
  }, {
    path: "/register/components/tos",
    component: _ef816d80,
    alias: "/register/components/tos/index.html",
    name: "register-components-tos"
  }, {
    path: "/",
    component: _2b6ba35a,
    alias: "/index.html",
    name: "index",
    children: [{
      path: "sections/banner",
      component: _53ce5eed,
      name: "index-sections-banner"
    }, {
      path: "sections/business",
      component: _ef51e17e,
      name: "index-sections-business"
    }, {
      path: "sections/enters",
      component: _619064dc,
      name: "index-sections-enters"
    }, {
      path: "sections/news",
      component: _e2be1558,
      name: "index-sections-news"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
