<template>
    <footer class="footer">
        <div class="content">
           

            <div class="bottom">
                <div class="copy-right">
                    <p>
                        <span class="item">
                            河北高速燕赵驿行集团有限公司
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <a class="item" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                            备案号：{{head.icp}}
                        </a>
                    </p>
               
                    <p>
                        地址：河北省石家庄高新区中山东路832号澳怡大厦18楼
                    </p>
                   
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import CONFIG from '@config/config';
    
    export default {
  
        props: {
            theme: {
                type: String,
                default: ''
            },
            fixed: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                head:CONFIG.head,
            }
        },
    }
</script>
<style scoped lang="scss">
	@import '@css/var.scss';

    $textColor: #949494;

	.footer{
		background:#64ad51;
		padding: 30px 0;
		color:white;

		.content-text{
			width: 1100px;
			display: inline-block;
		}

		.bottom{
			text-align: center;
            font-size: 16px;
			.copy-right{
				line-height: 1em;
				color:#fff;
                p{
                    line-height: 36px;
                }
				a{
					color:#fff;
				}

				
			}
		}
	}
</style>