export default { 
    head: {
        title: '',
        title2: '惠采购电子交易平台',
        citycode:'',//城市编号
        planName:'',
        dljgLoginUrl:'http://27.128.162.40:8088/otc/#/login?plan=',
        tbrLoginUrl:'http://27.128.162.40:9001/newtbr/index.html#/login?plan=',
        zjLoginUrl:'http://sdpingbiao.zchzb.com',
        meta: {
            description: '交易平台,招投标,电子交易平台',
            keywords: '交易平台,招投标,电子交易平台',
        },
        icon: '/favicon.ico',
        logo:'',
    },
    plugins: {
        element: true,
        myComs: true,
        vuexStorage: false,
        jquery: true,
        sentry: false,
        cnzz: false
    },
    proxy: {
        '/web': {
            target: 'http://192.168.1.107:9041',
            changeOrigin:true,
        },
    },
}
