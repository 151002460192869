export const state = () => ({
    config: {
        nav: [
            {
                title: '首页',
                href: '/',
                activeReg: /(^\/search*)|(^\/$)/,
            },
            
            {
                title: '交易信息',
                href: '/business',
                activeReg: /(^\/business*)/,
            },
            {
                title: '服务信息',
                href: '/news',
                activeReg: /(^\/news*)/,
            },
            {
                title: '帮助中心',
                href: '/guide',
                activeReg: /(^\/guide*)/,
            },
            {
                title: '下载中心',
                href: '/download',
                activeReg: /(^\/download*)/,
            },
            // {
            //     title: '关于我们',
            //     href: '/about',
            //     activeReg: /(^\/about*)/,
            // },
        ],
        linkArr:[
            {
                title: '中采惠招',
                href: 'http://www.zchzb.com',
            },
            {
                title: '中采E保',
                href: 'http://www.zcebao.com/',
            },
            {
                title: '惠采商城',
                href: 'http://zchr.xjf.limofang.cn/',
            },
            {
                title: '专家一体化平台',
                href: '/',
            },
        ],
        tel: '400-901-6886',
        email: 'hb@zchrkj.com',
        workTime: '9:00-21:30',
        record: '中财惠瑞（北京）科技发展有限公司',
        address: '北京市海淀区城西路辅路莲花苑5号 华宝大厦 619',
        products: [
            {
                title: 'xxxx',
                href: 'xxxxx'
            },
        ]
    }
})

export const mutations = {
    
}
